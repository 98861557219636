import * as React from "react";
import "../../../../styling/stream/stream.scss"
import "../../../../styling/common/index.scss"
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ZenContentInterface from "../ZenContentInterface";
import { PracticePreviewControls } from "./PracticePreviewControls";
import { MeditationIcons } from "../MeditationIcons";
import { Link } from "react-router-dom";

export const PracticePortalPreview = (props : ZenContentInterface) => {
    return (
      <div className="MeditationPortalStreamItem stream-item">
        <div className="PracticePortalStreamItemImageHolder">
        <img  className = "MeditationPortalStreamItemImage" src={props.image} ></img>
        </div>
        {props.title && props.title.length<25? 
          <h1 className = "focused-med-title focused-med-title-large okashi">
          {props.title}
          </h1>
        :
        <h1 className = "focused-med-title okashi">
            {props.title}
        </h1>
        }
        
        <h2 className = "focused-med-subheader zenK">
        {props && props.description? props.description.substring(0,175) : null}{props && props.description && props.description.length>175?'...':null}
        </h2>    
        <div style={{marginBottom:"15px"}}>
          <MeditationIcons {...props}></MeditationIcons>  
        </div>    
        <Link to={"/meditation/"+props.id}>
            <h4 style={{ width: "100%", margin: "auto", textAlign: "center", marginTop: "15px", marginBottom: "20px" }}>See More</h4>
          </Link>  
        <PracticePreviewControls {...props}/>
        
      </div>
    );
  
}